import spinner_button from "images/load.gif";
import logo_gmag from "images/logo-gasco.svg";
import spinner from "images/spinner.gif";
import * as moment from "moment";
import React, { Component } from "react";
import { res, validate } from "react-email-validator";
import FadeIn from "react-fade-in";
import Modal from "react-modal";
import NumberFormat from "react-number-format";
import { Button, Form } from "reactstrap";
import { getPdf, savePayment } from "../actions/index";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default class PayAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      loading_button: false,
      showModal: false,
      loading_modal: false,
      value: "",
      email: "",
      disabled_other: true,
      targetModal: "",
    };
    this.handleSubmitTotalAmount = this.handleSubmitTotalAmount.bind(this);
    this.handleSubmitPreviousBalance =
      this.handleSubmitPreviousBalance.bind(this);
    this.handleSubmitPayDebt = this.handleSubmitPayDebt.bind(this);
    this.pdfClickHandler = this.pdfClickHandler.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeValue = this.handleChangeValue.bind(this);
    this.handleChangeRadio = this.handleChangeRadio.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
  }

  componentDidMount() {
    // $("#button_covid_modal").click();
    // $("#button_maintenance_modal").click();
    //  $("#button_digital_dispatch_modal").click();
  }

  async handleSubmitTotalAmount(event) {
    event.preventDefault();
    let {
      current_debt,
      client_number,
      web_pay_success_account_total_amount_url,
      web_pay_fail_url,
      web_pay_error_url,
    } = this.state;
    if (event.target.notify.checked && event.target.email.value === "") {
      return alert("Debe ingresar un email");
    }
    if (event.target.notify.checked && event.target.email.value !== "") {
      validate(event.target.email.value);
      if (!res) {
        return alert("Email inválido");
      }
    }
    event.persist();
    let response = await savePayment(
      this.serializeTotalAmount($(event.target.notify).is(":checked")),
      (response) => {
        if (response.status === 201) {
          event.target.method = "post";
          event.target.action = process.env.URL_PAGO_GASCO + "/Default.aspx";
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfVkorg",
              value: "4000",
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfTipoTRX",
              value: "2",
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfKCC",
              value: process.env.KCC,
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfNumeroCliente",
              value: client_number,
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfNumeroDocumento",
              value: current_debt.document_number,
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfMontoTotal",
              value: current_debt.balance,
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfUserName",
              value: "ZWu",
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfUrlExito",
              value: web_pay_success_account_total_amount_url,
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfUrlFracaso",
              value: web_pay_fail_url,
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfUrlError",
              value: web_pay_error_url,
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfretry",
              value: "0",
            })
            .appendTo(event.target);
          event.target.submit();
        } else {
          alert("Error al procesar la información");
          console.log(response);
        }
      }
    );
  }

  async handleSubmitPreviousBalance(event) {
    event.preventDefault();
    let {
      current_debt,
      client_number,
      web_pay_success_account_previous_balance_url,
      web_pay_fail_url,
      web_pay_error_url,
    } = this.state;
    let { previous_balance } = this.props;
    if (event.target.notify.checked && event.target.email.value === "") {
      return alert("Debe ingresar un email");
    }
    if (event.target.notify.checked && event.target.email.value !== "") {
      validate(event.target.email.value);
      if (!res) {
        return alert("Email inválido");
      }
    }
    event.persist();
    let response = await savePayment(
      this.serializePreviousBalance($(event.target.notify).is(":checked")),
      (response) => {
        if (response.status === 201) {
          event.target.method = "post";
          event.target.action = process.env.URL_PAGO_GASCO + "/Default.aspx";
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfVkorg",
              value: "4000",
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfTipoTRX",
              value: "2",
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfKCC",
              value: process.env.KCC,
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfNumeroCliente",
              value: client_number,
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfNumeroDocumento",
              value: current_debt.document_number,
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfMontoTotal",
              value: previous_balance,
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfUserName",
              value: "ZWu",
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfUrlExito",
              value: web_pay_success_account_previous_balance_url,
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfUrlFracaso",
              value: web_pay_fail_url,
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfUrlError",
              value: web_pay_error_url,
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfretry",
              value: "0",
            })
            .appendTo(event.target);
          event.target.submit();
        } else {
          alert("Error al procesar la información");
          console.log(response);
        }
      }
    );
  }

  async handleSubmitPayDebt(event) {
    event.preventDefault();
    const {
      current_debt,
      client_number,
      web_pay_success_account_pay_debt_url,
      web_pay_fail_url,
      web_pay_error_url,
    } = this.state;
    var amount = event.target.amount.value;
    if (amount !== "") {
      amount = amount.includes(".")
        ? parseInt(amount.replaceAll(".", ""))
        : parseInt(amount);
    } else {
      return alert("Debe ingresar un monto");
    }
    event.persist();
    let response = await savePayment(
      this.serializePayDebt(amount, $(event.target.notify).is(":checked")),
      (response) => {
        if (response.status === 201) {
          event.target.method = "post";
          event.target.action = process.env.URL_PAGO_GASCO + "/Default.aspx";
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfVkorg",
              value: "4000",
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfTipoTRX",
              value: "2",
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfKCC",
              value: process.env.KCC,
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfNumeroCliente",
              value: client_number,
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfNumeroDocumento",
              value: current_debt.document_number,
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfMontoTotal",
              value: amount,
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfUserName",
              value: "ZWu",
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfUrlExito",
              value: web_pay_success_account_pay_debt_url,
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfUrlFracaso",
              value: web_pay_fail_url,
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfUrlError",
              value: web_pay_error_url,
            })
            .appendTo(event.target);
          $("<input>")
            .attr({
              type: "hidden",
              name: "hfretry",
              value: "0",
            })
            .appendTo(event.target);
          event.target.submit();
        } else {
          alert("Error al procesar la información");
          console.log(response);
        }
      }
    );
  }

  serializeTotalBill() {
    const { current_debt } = this.state;
    var formData = new FormData();
    formData.append("payment[number]", current_debt.document_number);
    formData.append("payment[tbk_amount]", current_debt.total_paid);
    formData.append("payment[total_bill]", true);

    return formData;
  }

  serializePreviousBalance(send_email_notification) {
    const { current_debt, client_number, email } = this.state;
    let { previous_balance } = this.props;
    var formData = new FormData();
    formData.append("payment[number]", current_debt.document_number);
    formData.append("payment[client_number]", client_number);
    formData.append("payment[tbk_amount]", previous_balance);
    formData.append("payment[previous_balance]", true);
    formData.append(
      "payment[send_email_notification]",
      send_email_notification
    );
    formData.append("payment[email_notification]", email);

    return formData;
  }

  serializeTotalAmount(send_email_notification) {
    const { current_debt, client_number, email } = this.state;
    var formData = new FormData();
    formData.append("payment[number]", current_debt.document_number);
    formData.append("payment[client_number]", client_number);
    formData.append("payment[tbk_amount]", current_debt.balance);
    formData.append("payment[total_amount]", true);
    formData.append(
      "payment[send_email_notification]",
      send_email_notification
    );
    formData.append("payment[email_notification]", email);

    return formData;
  }

  serializePayDebt(value, send_email_notification) {
    const { current_debt, client_number, email } = this.state;
    var formData = new FormData();
    formData.append("payment[client_number]", client_number);
    formData.append("payment[number]", current_debt.document_number);
    formData.append("payment[tbk_amount]", value);
    formData.append("payment[pay_debt]", true);
    formData.append(
      "payment[send_email_notification]",
      send_email_notification
    );
    formData.append("payment[email_notification]", email);

    return formData;
  }

  toggleModal(event) {
    let { showModal } = this.state;
    showModal = !showModal;
    this.setState({
      showModal,
      loading_modal: true,
      targetModal: event.target.dataset.target,
      email: "",
      checked: false,
    });
    setTimeout(
      function () {
        this.setState({ loading_modal: false });
      }.bind(this),
      1500
    );
  }

  handleChangeValue(event) {
    var amount = event.target.value;
    if (amount !== "") {
      amount = amount.includes(".")
        ? parseInt(amount.replaceAll(".", ""))
        : parseInt(amount);
    }
    this.setState({ value: amount });
  }

  handleChangeEmail(event) {
    this.setState({
      email: event.target.value,
      checked: event.target.value !== "",
    });
  }

  handleChangeRadio(event) {
    let { disabled_other } = this.state;
    disabled_other = event.target.id === "amount";
    this.setState({ disabled_other: disabled_other });
  }

  handleChangeCheckbox(event) {
    this.setState({ checked: $(event.target).is(":checked") });
  }

  renderModal() {
    const {
      loading_modal,
      current_debt,
      value,
      email,
      disabled_other,
      checked,
      targetModal,
    } = this.state;
    return (
      <Modal
        isOpen={this.state.showModal}
        toggle={this.toggleModal}
        style={customStyles}
      >
        <div className={"modal-header"}>
          <img src={logo_gmag} className="img-center logo-container" />
        </div>
        <div className={"modal-body"}>
          {loading_modal ? (
            <FadeIn>
              <div style={{ paddingBottom: "80px" }}>
                <img src={spinner} className="img-center" />
              </div>
            </FadeIn>
          ) : targetModal === "pay-debt" ? (
            <Form
              onSubmit={this.handleSubmitPayDebt}
              className="form_pay_debt"
              id={targetModal}
            >
              <h4>Seleccione una opción de abono</h4>
              <br />
              <div className="border-blue">
                <p>
                  Para realizar un abono a su deuda puede seleccionar cualquier
                  monto entre $1 y el monto total de esta.
                </p>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <label>
                    <input
                      type="radio"
                      className="form-check-input"
                      name="amount"
                      id="amount"
                      value={current_debt.balance}
                      required
                      onChange={this.handleChangeRadio}
                    />{" "}
                    Monto Total
                  </label>
                </div>
                <div className="col-md-6 pull-right" style={{ width: "auto" }}>
                  <NumberFormat
                    className="price"
                    value={current_debt.balance}
                    displayType={"text"}
                    thousandSeparator={"."}
                    prefix={"$"}
                    decimalSeparator={","}
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <label>
                    <input
                      type="radio"
                      className="form-check-input"
                      name="amount"
                      id="amount_other"
                      value={value}
                      onChange={this.handleChangeRadio}
                    />{" "}
                    Otro Monto
                  </label>
                </div>
                <div className="col-md-6 pull-right" style={{ width: "275px" }}>
                  <div className="input-group">
                    <span className="input-group-addon"> $</span>
                    <NumberFormat
                      className="form-control"
                      value={value}
                      thousandSeparator={"."}
                      decimalSeparator={false}
                      onChange={this.handleChangeValue}
                      disabled={disabled_other}
                      name="other"
                      id="other"
                      placeholder="Ingrese otro monto"
                      allowNegative={false}
                      isAllowed={(values) => {
                        const { formattedValue, floatValue } = values;
                        return (
                          formattedValue === "" ||
                          (floatValue >= 1 &&
                            floatValue <= current_debt.balance)
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="notify"
                      id="notify"
                      checked={checked}
                      onChange={this.handleChangeCheckbox}
                    />{" "}
                    Notificar al email
                  </label>
                </div>
                <div className="col-md-6 pull-right" style={{ width: "275px" }}>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    id="email"
                    value={email}
                    onChange={this.handleChangeEmail}
                  />
                </div>
              </div>
            </Form>
          ) : (
            <Form
              onSubmit={
                targetModal === "previous-balance"
                  ? this.handleSubmitPreviousBalance
                  : this.handleSubmitTotalAmount
              }
              className="form_pay_debt"
              id={targetModal}
            >
              <h4>Notificación de Pago</h4>
              <br />
              <div className="border-blue">
                <p>
                  Si desea recibir una notificación de su pago al correo
                  electrónico, marque la casilla "Notificación al email".
                </p>
              </div>
              <br />
              <div className="row">
                <div className="col-md-4">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="notify"
                      id="notify"
                      checked={checked}
                      onChange={this.handleChangeCheckbox}
                    />{" "}
                    Notificar al email
                  </label>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    id="email"
                    value={email}
                    onChange={this.handleChangeEmail}
                  />
                </div>
              </div>
            </Form>
          )}
        </div>
        <div className={"modal-footer"}>
          <Button color="danger" onClick={this.toggleModal}>
            Cancelar
          </Button>
          <Button color="success" form={targetModal} key="submit" type="submit">
            Confirmar
          </Button>
        </div>
      </Modal>
    );
  }

  renderBlock() {
    const { current_debt, pdf, payment, loading_button } = this.state;
    const { previous_balance } = this.props;
    let state, price, pb, total_amount;
    let payed = false;
    let total_paid = current_debt.total_paid < 0 ? 0 : current_debt.total_paid;
    let price_pendiente = "price-saldo-pendiente";
    if (previous_balance === null) {
      pb = false;
    } else {
      pb = true;
    }
    if (current_debt.balance <= 0 || !current_debt.pending) {
      total_amount = false;
      state = "Cancelada";
      price = "price-cancelada";
      payed = true;
    } else {
      total_amount = true;
      state = "Pendiente";
      price = "price-pendiente";
      payed = false;
    }
    return (
      <div className="bloque-gris">
        <div className="row title">
          <div className="col-md-6">
            <h4>Última Boleta</h4>
          </div>
          <div className="col-md-3">
            {pb ? (
              <Button
                type="button"
                className="btn pagar-boleta transition"
                onClick={this.toggleModal}
                data-target="previous-balance"
              >
                Pagar Saldo Anterior
              </Button>
            ) : null}
          </div>
          {/*<div className="col-md-3" style={{width: "auto"}}>
          { total_amount ? (
            <Button type="button" className="btn pagar-boleta transition" onClick={this.toggleModal} data-target="pay-debt">
              Abono
            </Button>
          ) : null
          }
          </div>*/}
          <div className="col-md-3" style={{ width: "auto" }}>
            {total_amount ? (
              <Button
                type="button"
                className="btn pagar-boleta transition"
                onClick={this.toggleModal}
                data-target="total-amount"
              >
                Pagar Monto Total
              </Button>
            ) : null}
          </div>
          {this.renderModal()}
        </div>
        <div className="row title">
          <div className="col-md-12">
            <h5>
              Si aún no aparece reflejado su pago, no lo vuelva a realizar. Los
              pagos efectuados pueden llevar algunos minutos en verse reflejados
              en sistema.
            </h5>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-4 col-sm-12 container-boleta-mes">
            {payment && current_debt.balance > 0 ? (
              <div>
                <p>
                  <b>Pendiente de pago</b>
                </p>
                <p className={"price" + " " + price_pendiente}>
                  <NumberFormat
                    value={current_debt.balance}
                    displayType={"text"}
                    thousandSeparator={"."}
                    prefix={"$"}
                    decimalSeparator={","}
                  />
                </p>
              </div>
            ) : null}
            <p>
              <b>Boleta {current_debt.issue_date}</b>
            </p>
            <p className={"price" + " " + price}>
              <NumberFormat
                value={total_paid}
                displayType={"text"}
                thousandSeparator={"."}
                prefix={"$"}
                decimalSeparator={","}
              />
            </p>
            {pdf !== null && pdf !== "" && pdf !== undefined ? (
              <a
                href={pdf}
                target="blank"
                className="btn ver-boleta transition"
              >
                Ver Boleta
              </a>
            ) : (
              <a
                href="#"
                target="blank"
                className="btn ver-boleta transition"
                onClick={this.pdfClickHandler}
                disabled={loading_button}
                style={loading_button ? { width: "auto", height: "auto" } : {}}
              >
                {loading_button ? (
                  <img src={spinner_button} className="img-center" />
                ) : (
                  "Ver Boleta"
                )}
              </a>
            )}
          </div>
          <div className="col-md-8 col-sm-12">
            <table className="tabla-consumo">
              <tbody>
                <tr>
                  <td>Fecha de vencimiento:</td>
                  <td className="table-bold">{current_debt.due_date}</td>
                </tr>
                <tr>
                  <td>Estado de pago:</td>
                  <td className={"table-bold price" + " " + price}>{state}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  pdfClickHandler(event) {
    event.preventDefault();
    let { pdf, loading_button } = this.state;
    if (loading_button) {
      return;
    }
    this.setState({ loading_button: true });
    if (pdf == "") {
      getPdf(this.serializePdfClick(), (response) => {
        this.setState({ loading_button: false });
        if (response.status === 200) {
          this.setState({ pdf: response.data.pdf });
          if (response.data.pdf !== "") {
            window.open(response.data.pdf);
          }
        }
      });
    } else {
      this.setState({ loading_button: false });
      window.open(pdf);
    }
  }

  serializePdfClick() {
    const { current_debt, client_number } = this.state;
    var formData = new FormData();
    formData.append("client_number", client_number);
    formData.append("document_number", current_debt.document_number);

    return formData;
  }

  render() {
    const current_day = moment().format("dddd DD");
    const current_month_year = moment().locale("es").format("MMMM / YYYY");
    const { current_debt, valid_client, client_message } = this.state;
    if (current_debt) {
      return (
        <div className="container">
          <div className="col-md-10 col-md-offset-1">
            <div className="container-white-box">
              <div className="row">
                <div className="col-md-12">
                  <div className="title-btn-flex">
                    <h1>Pague su Cuenta</h1>
                    <div className="fecha-actual">
                      <h4>{current_day}</h4>
                      <p>{current_month_year}</p>
                    </div>
                  </div>
                  {this.renderBlock()}
                  <a className="btn btn-form" href={process.env.URL_GASCO}>
                    Volver
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="col-md-12">
          <div className="container-white-box">
            <div className="row">
              <div className="col-md-12">
                <div className="title-btn-flex">
                  <h1>Pague su Cuenta</h1>
                  <div className="fecha-actual">
                    <h4>{current_day}</h4>
                    <p>{current_month_year}</p>
                  </div>
                </div>

                <div className="bloque-gris">
                  <p className="error">
                    {client_message || "Cuenta no encontrada"}
                  </p>
                </div>
              </div>
              <a className="btn btn-form" href={process.env.URL_GASCO}>
                Volver
              </a>
            </div>
          </div>
        </div>
      );
    }
  }
}

import axios from 'axios';

export function getBillHistorical(callback){
  const promise = axios.get(`/api/v1/documents/bills`)
  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      if (error.response) {
        callback(error.response);
      }
    })
}

export function getData(callback){
  const promise = axios.get(`/api/v1/documents/bills/data`)
  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      if (error.response) {
        callback(error.response);
      }
    })
}

export function savePayment(values, callback){
  const promise = axios.post(`/api/v1/payments`, values)
  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      if (error.response) {
        callback(error.response);
      }
    })
}

export function updateAmounts(values, callback){
  const promise = axios.put(`/api/v1/documents/update_amounts`, values)
  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      if (error.response) {
        callback(error.response);
      }
    })
}

export function getPdf(values, callback){
  const promise = axios.post(`/api/v1/documents/bills/pdf`, values)
  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      if (error.response) {
        callback(error.response);
      }
    })
}

export function setAfterLogin(callback){
  const promise = axios.get(`/api/v1/documents/set_after_login`)
  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      if (error.response) {
        callback(error.response);
      }
    })
}

export function validateClient(callback){
  const promise = axios.get(`/api/v1/clients/validate_client`)
  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      if (error.response) {
        callback(error.response);
      }
    })
}

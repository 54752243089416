import React, { Component } from 'react';
import {
  Button, Form, Container, Row, Col, FormGroup, Input
} from 'reactstrap';
import Modal from 'react-modal';
import _ from 'lodash';
import * as moment from 'moment';
import { ColumnChart } from 'react-chartkick'
import NumberFormat from 'react-number-format';
import {
  getData, getBillHistorical, getCurrentDebts, savePayment
} from '../actions/index';
import spinner from 'images/spinner.gif';
import FadeIn from 'react-fade-in';
import { validate, res } from 'react-email-validator';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

export default class PayGlpAccount extends Component {
  constructor(props){
    super(props);
    this.state = {
      ...props,
      current_debt: {},
      data_chart: {},
      first_data: '',
      last_data: '',
      messages: [],
      max_consumption: [],
      min_consumption: [],
      average_consumption: 0,
      error: '',
      loading: true,
      data: {},
      showModal: false,
      current_debts: [],
      error_current_debts: '',
      loading_current_debts: false,
      email: ''
    }
    this.toggleModal = this.toggleModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
  }

  componentDidMount() {
    // $("#button_covid_modal").click();
    // $("#button_maintenance_modal").click();
  }

  async handleSubmit(event){
    event.preventDefault();
    let {
      client_number, web_pay_success_glp_account_url,
      web_pay_fail_url, web_pay_error_url,
      current_debts
    } = this.state;
    var total_amount = 0;
    var document_number = [];
    var document_with_quote = "";
    var check_boxes;
    check_boxes = $(event.target.elements).filter('input[type=checkbox]');
    if (check_boxes.filter(':checked').length === 0) {
      return alert("Debe seleccionar al menos una deuda.");
    }
    if (event.target.notify.checked && event.target.email.value === "") {
      return alert("Debe ingresar un email")
    }
    if (event.target.notify.checked && event.target.email.value !== "") {
      validate(event.target.email.value)
      if (!res) {
        return alert("Email inválido")
      }
    }
    _.map(current_debts, (current_debt, index) => {
      _.map(check_boxes, (input, idx) => {
        if ($(input).is(':checked') && ("Pago0" + (index+1) === $(input).attr('name'))) {
          total_amount = parseInt(total_amount) + parseInt(current_debt.monto)
          document_with_quote = current_debt.numerodocumento + "-" + current_debt.numerodecuota
          document_number.push(document_with_quote.replace(/^0+/, ''))
          $(input).attr({
            value: current_debt.numerodocumento + ";" +
              current_debt.numerodecuota + ";" +
              current_debt.monto + ";" +
              current_debt.bukrs + ";" +
              current_debt.belnr + ";" +
              current_debt.gjahr + ";" +
              current_debt.buzei + ";" +
              current_debt.fechadpi + ";" +
              current_debt.montodpi + ";" +
              current_debt.ufdpi + ";" +
              current_debt.interesdpi + ";" +
              current_debt.interesmldpi + ";" +
              current_debt.cobexternadpi + ";" +
              current_debt.fechavtodpi + ";" +
              current_debt.diasmoradpi + ";" +
              "#"
          })
        }
      });
    });
    document_number.sort(function(a,b) {
      if (isNaN(a) || isNaN(b)) {
        return a > b ? 1 : -1;
      }
      return a - b;
    });
    document_number = _.join(document_number, ", ")
    event.persist();
    let response = await savePayment(this.serializeTotalBill(total_amount, document_number, $(event.target.notify).is(":checked")), (response) => {
      if (response.status === 201) {
        event.target.method = "post";
        event.target.action = process.env.URL_PAGO_GASCO + "/Default.aspx";
        $('<input>').attr({
          type: 'hidden',
          name: 'hfVkorg',
          value: '4000'
        }).appendTo(event.target);
        $('<input>').attr({
          type: 'hidden',
          name: 'hfTipoTRX',
          value: '1'
        }).appendTo(event.target);
        $('<input>').attr({
          type: 'hidden',
          name: 'hfKCC',
          value: process.env.KCCGLP
        }).appendTo(event.target);
        $('<input>').attr({
          type: 'hidden',
          name: 'hfNumeroCliente',
          value: client_number
        }).appendTo(event.target);
        $('<input>').attr({
          type: 'hidden',
          name: 'hfMontoTotal',
          value: total_amount
        }).appendTo(event.target);
        $('<input>').attr({
          type: 'hidden',
          name: 'hfUserName',
          value: 'ZWu'
        }).appendTo(event.target);
        $('<input>').attr({
          type: 'hidden',
          name: 'hfUrlExito',
          value: web_pay_success_glp_account_url
        }).appendTo(event.target);
        $('<input>').attr({
          type: 'hidden',
          name: 'hfUrlFracaso',
          value: web_pay_fail_url
        }).appendTo(event.target);
        $('<input>').attr({
          type: 'hidden',
          name: 'hfUrlError',
          value: web_pay_error_url
        }).appendTo(event.target);
        $('<input>').attr({
          type: 'hidden',
          name: 'hfretry',
          value: '0'
        }).appendTo(event.target);
        event.target.submit();
      }else {
        alert("Error al procesar la información");
        console.log(response);
      }
    });
  }

  serializeTotalBill(total_amount, document_number, send_email_notification){
    let { client_number, email } = this.state;
    var formData = new FormData();
    formData.append('payment[client_number]', client_number);
    formData.append('payment[number]', document_number);
    formData.append('payment[tbk_amount]', total_amount);
    formData.append('payment[total_bill]', true);
    formData.append('payment[send_email_notification]', send_email_notification);
    formData.append('payment[email_notification]', email);

    return formData;
  }

  toggleModal(){
    let { showModal } = this.state;
    showModal = !showModal;
    this.setState({
      showModal,
      loading_current_debts: true,
      email: "",
      checked: false
    });
    setTimeout(function(){
      this.setState({
        loading_current_debts: false,
        current_debts: this.props.current_debts
      });
    }.bind(this), 1500);
  }

  handleChangeEmail(event) {
    this.setState({
      email: event.target.value,
      checked: event.target.value !== ""
    });
  }

  handleChangeCheckbox(event){
    this.setState({checked: $(event.target).is(":checked")})
  }

  renderModal(){
    let { current_debts, error_current_debts, loading_current_debts, email, checked } = this.state;
    return(
      <Modal isOpen={this.state.showModal} toggle={this.toggleModal} style={customStyles}>
        <div className={'modal-header'}>
          <h3>Pagar deudas</h3>
        </div>
        <div className={'modal-body'}>
          {loading_current_debts ? (
            <FadeIn>
              <div style={{paddingBottom: '80px'}}>
                <img src={spinner} className="img-center"/>
              </div>
            </FadeIn>
          ) : (
            <Form onSubmit={this.handleSubmit} className="form_pago" id="glp-form">
              <ul>
                {_.map(current_debts, (current_debt, index) => (
                  <li key={index}>
                    <Input
                      type="checkbox"
                      name={"Pago0" + (index+1)}
                      id={"Pago0" + (index+1)}
                    />{' '}
                    {"Documento "}{current_debt.numerodocumento}{"; Cuota " + current_debt.numerodecuota + "; Monto"}{
                      <NumberFormat
                        value={current_debt.monto ? current_debt.monto : 0}
                        displayType={'text'}
                        thousandSeparator={'.'}
                        prefix={'$'}
                        decimalSeparator={','}
                      />
                    }
                  </li>
                ))}
              </ul>
              <div className="row">
                <div className="col-md-4">
                  <label>
                    <input type="checkbox" className="form-check-input" name="notify" id="notify" checked={checked} onChange={this.handleChangeCheckbox}/> Notificar al email
                  </label>
                </div>
                <div className="col-md-8">
                  <input type="text" className="form-control" name="email" id="email" value={email} onChange={this.handleChangeEmail}/>
                </div>
              </div>
            </Form>
          )}
        </div>
        <div className={'modal-footer'}>
          <Button color="danger" onClick={this.toggleModal}>Cancelar</Button>
          <Button color="success" form="glp-form" key="submit" type="submit">
            Pagar
          </Button>
        </div>
      </Modal>
    )
  }

  renderBlock(){
    let { current_debts, total_debt } = this.props;
    return(
      <div className="bloque-gris">
        <div className="title-btn-flex">
          <h4>Deuda Total</h4>
          <Button type="button" className="btn pagar-boleta transition" onClick={this.toggleModal}>
            Pagar Deudas
          </Button>
          {this.renderModal()}
        </div>
        <hr/>
        <div className="row">
          <div className="col-md-4 col-sm-12 container-boleta-mes">
            <p><b>Consumo Total</b></p>
            <p className="price-cancelada price">
              <NumberFormat
                value={total_debt ? total_debt : 0}
                displayType={'text'}
                thousandSeparator={'.'}
                prefix={'$'}
                decimalSeparator={','}
              />
            </p>
            {/*<a href={current_debt.pdf} target="_blank" className="btn ver-boleta transition">Ver Documento</a>*/}
          </div>
          <div className="col-md-8 col-sm-12">
            <table className="tabla-consumo">
            <tbody>
            <tr>
            <td>Fecha de vencimiento:</td>
            <td className="table-bold">{current_debts ? moment(current_debts[0].fechavencimiento).format("DD-MM-YYYY") : ""}</td>
            </tr>
            </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }

  render() {
    let { error, loading, current_debts } = this.state;
    const current_day = moment().format('dddd DD');
    const current_month_year = moment().locale('es').format('MMMM / YYYY');
    if (current_debts) {
      return(
        <div className="container">
          <div className="col-md-10 col-md-offset-1">
            <div className="container-white-box">
              <div className="row">
                <div className="col-md-12">
                  <div className="title-btn-flex">
                    <h1>Pague su Cuenta</h1>
                    <div className="fecha-actual">
                      <h4>{current_day}</h4>
                      <p>{current_month_year}</p>
                    </div>
                  </div>
                  {this.renderBlock()}
                <a className="btn btn-form" href={process.env.URL_GASCO}>Volver</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }else {
      return(
        <div className="col-md-12">
          <div className="container-white-box">
            <div className="row">
              <div className="col-md-12">
                <div className="title-btn-flex">
                  <h1>Pague su Cuenta</h1>
                  <div className="fecha-actual">
                    <h4>{current_day}</h4>
                    <p>{current_month_year}</p>
                  </div>
                </div>

                <div className="bloque-gris">
                  <p>Cuenta no encontrada</p>
                </div>

              </div>
              <a className="btn btn-form" href={process.env.URL_GASCO}>Volver</a>
            </div>
          </div>
        </div>
      )
    }
  }
}

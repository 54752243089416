import axios from 'axios';

export function saveOrder(values, callback){
  const promise = axios.post(`/api/v1/orders`, values)
  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      if (error.response) {
        callback(error.response);
      }
    })
}

import React, { Component } from 'react';
import { Button, Form, Container, Row, Col, FormGroup, Input } from 'reactstrap';
import _ from 'lodash';
import * as moment from 'moment';
import { ColumnChart } from 'react-chartkick'
import NumberFormat from 'react-number-format';
import {
  getData, getBillHistorical, getPdf
} from '../actions/index';
import spinner from 'images/spinner.gif';
import spinner_button from 'images/load.gif';
import FadeIn from 'react-fade-in';

export default class GlpBillsHistorical extends Component {
  constructor(props){
    super(props);
    this.state = {
      ...props,
      data_chart: {},
      current_debt: {},
      documents: [],
      error: '',
      loading: true
    };
    this.pdfClickHandler = this.pdfClickHandler.bind(this);
  }

  componentDidMount() {
    setTimeout(function(){
      getData((response) => {
        this.setState({ loading: false });
        if (response.status === 200) {
          this.setState({
            data_chart: response.data.data_chart,
            current_debt: response.data.current_debt
          });
        }else {
          this.setState({ error: 'Ha ocurrido un error al cargar los datos.' })
        }
      })
      getBillHistorical((response) => {
        this.setState({ loading: false });
        if (response.status === 200) {
          this.setState({ documents: response.data });
        }else {
          this.setState({ error: 'Ha ocurrido un error al cargar los datos.' })
        }
      })
    }.bind(this), 1500);
  }

  pdfClickHandler(event, document_number){
    event.preventDefault();
    let newDocuments = [...this.state.documents];
    let document = _.find(newDocuments, { 'document_number': document_number });
    let documentIndex = _.findIndex(newDocuments, { 'document_number': document_number });
    if (document.loading_button) {
      return
    }
    document.loading_button = true;
    newDocuments[documentIndex] = document;
    this.setState({ documents: newDocuments });
    getPdf(this.serializePdfClick(document), (response) => {
      if (response.status === 200) {
        document.loading_button = false;
        newDocuments[documentIndex] = document;
        this.setState({ documents: newDocuments });
        if (response.data.pdf !== '') {
          document.pdf = response.data.pdf;
          newDocuments[documentIndex] = document;
          this.setState({ documents: newDocuments });
          window.open(response.data.pdf);
        }
      }
    });
  }

  serializePdfClick(document){
    let { current_user, client } = this.state;
    var formData = new FormData();
    formData.append('user_rut', current_user.rut);
    formData.append('user_token', current_user.authentication_token);
    formData.append('document_number', document.document_number);

    return formData;
  }

  render() {
    let { data_chart, documents, error, loading, client, current_debt, previous_balance } = this.state;
    const current_day = moment().format('dddd DD');
    const current_month = moment().locale('es').format('MMMM / YYYY');
    if (loading) {
      return(
        <div className="col-md-9">
          <div className="container-white-box">
            <FadeIn>
              <div style={{paddingBottom: '80px'}}>
                <img src={spinner} className="img-center"/>
              </div>
            </FadeIn>
          </div>
        </div>
      )
    }else {
      if (error.trim() !== '') {
        return(
          <div className="col-md-9">
            <div className="container-white-box">
              <FadeIn>
                <p>{error}</p>
              </FadeIn>
          </div>
        </div>
        )
      }
      return(
        <div className="col-md-9">
          <div className="container-white-box">
            <div className="row">
              <div className="col-md-12">
                <FadeIn>
                  <div className="title-btn-flex">
                    <div className="titulos-seccion">
                      <h1>Historial de Facturación</h1>
                      <h2>Consumo por meses</h2>
                    </div>
                    <div className="fecha-actual">
                      <h4>{current_day}</h4>
                      <p>{current_month}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12" styles={{paddingTop: '15px', paddingBottom: '15px'}}>
                      <ColumnChart data={data_chart} label="L" colors={["#9a0031"]} ytitle="Consumo L" library={{hAxis: {slantedText:true,slantedTextAngle:45}}} />
                    </div>
                  </div>


                  <div className="row">
                    <div className="col-md-12">
                      <div className="bloque-gris">
                        {/*<div className="payment" align="center">
                          {this.renderPayCurrentDebt(current_debt)}
                          {this.renderPayPreviousBalance(current_debt, previous_balance, client)}
                        </div>*/}
                        <div className="row">
                          <div className="col-md-12">
                            <table className="tabla-consumo-historial">
                              <thead>
                                <tr>
                                  <td className="table-bold">Periodo</td>
                                  <td className="table-bold hidden-xs">Consumo L</td>
                                  <td className="table-bold">Total Boleta</td>
                                  <td className="table-bold">Nº Boleta</td>
                                  {/*<td className="table-bold">Estado</td>*/}
                                </tr>
                              </thead>
                              <tbody>
                                {_.map(documents, (document, index) => (
                                  <tr className="tableblock" key={document.document_number}>
                                    <td>{document.due_date}</td>
                                    <td className="hidden-xs">{document.consumption_m3}</td>
                                    <td>
                                      <NumberFormat
                                        className="amount"
                                        value={parseInt(document.total_paid) + parseInt(document.tax)}
                                        displayType={'text'}
                                        thousandSeparator={'.'}
                                        prefix={'$'}
                                        decimalSeparator={','}
                                      />
                                    </td>
                                    <td>
                                      { document.pdf !== null && document.pdf !== "" && document.pdf !== undefined ? (
                                          <a href={document.pdf} target="blank">{Number(document.sii_document_number)}</a>
                                        ) : (
                                          <a href="#" target="blank" onClick={(e) => {this.pdfClickHandler(e, document.document_number)}} disabled={document.loading_button}>
                                          { document.loading_button ? <img src={spinner_button}/> : Number(document.sii_document_number) }
                                          </a>
                                        )
                                      }
                                    </td>
                                    {/*this.renderState(document.aasm_state)*/}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </FadeIn>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

}

import React, {Component} from 'react'
import _ from 'lodash';

export default class Addresses extends Component {
  constructor(props){
    super(props)
    this.state = {...props}
    this.addressTitle = this.addressTitle.bind(this);
    this.renderButtonEdit = this.renderButtonEdit.bind(this);
    this.renderButtonSelect = this.renderButtonSelect.bind(this);
  }

  componentDidMount(){
  }

  addressTitle(address){
    if (address.main_address === true) {
      return("Dirección")
    }else {
      return("Dirección Ingresada")
    }
  }

  renderButtonEdit(address){
    if (address.main_address === false) {
      return(
        <button type="button" className="btn btn-form gray pull-right" onClick={this.props.handleEdit.bind(this,address)}>Editar</button>
      )
    }else {
      return null;
    }
  }

  renderButtonSelect(address){
    if (address.selected) {
      return(
        <button type="button" className={"btn btn-form pull-right " + address.selected} onClick={this.props.handleSelect.bind(this,address)}>Seleccionada</button>
      )
    }else {
      return(
        <button type="button" className={"btn btn-form pull-right " + address.selected} onClick={this.props.handleSelect.bind(this,address)}>Seleccionar</button>
      )
    }
  }

  render(){
    const { addresses, cartItems } = this.props;
    if (addresses === null || addresses === undefined || _.isEmpty(addresses)) {
      return null;
    }
    return(
      <div>
        <p>Selecciona la dirección a la cual será enviada tu orden</p>
        {_.map(addresses, (address, index) => (
          <div className={"bloque-gris direccion row " + address.selected} key={index}>
            <div className="elementos-direccion">
              <h4>{this.addressTitle(address)}</h4>
              <p className="direccion">{address.street_name} {address.street_number}</p>
              <p>{address.commune}</p>
              <p>{address.city}</p>
            </div>
            <div className="elementos-direccion buttons">
              {this.renderButtonSelect(address)}
              {this.renderButtonEdit(address)}
            </div>
          </div>
        ))}
      </div>
    )
  }
}

import axios from "axios";

export function savePayment(values, callback) {
  const promise = axios.post(`/payments`, values);
  promise
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      if (error.response) {
        callback(error.response);
      }
    });
}

export function updateAmounts(values, callback) {
  const promise = axios.put(`/api/v1/documents/update_amounts`, values);
  promise
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      if (error.response) {
        callback(error.response);
      }
    });
}

export function getPdf(values, callback) {
  const promise = axios.post(`/web_pay/pdf.json`, values);
  promise
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      if (error.response) {
        callback(error.response);
      }
    });
}

export function validateClient(callback) {
  const promise = axios.get(`/api/v1/clients/validate_client`);
  promise
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      if (error.response) {
        callback(error.response);
      }
    });
}

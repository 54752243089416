import React, { Component } from 'react';
import { Button, Form, Container, Row, Col, FormGroup, Input } from 'reactstrap';
import _ from 'lodash';

export default class Products extends Component {
  constructor(props){
    super(props);
    this.state = {
      ...props,
      selectedProduct: {}
    };
  }

  componentDidMount(){
  }

  addToCart(product){
    this.setState({
      selectedProduct: {
        id: product.id,
        price: product.price,
        attachment: product.attachment,
        quantity: product.quantity,
        product_format: product.product_format
      }
    }, function(){
      this.props.addToCart(this.state.selectedProduct);
    });
  }

  render(){
    const { products } = this.props;
    if (products === null || products === undefined || _.isEmpty(products)) {
      return null;
    }
    return(
      <div className="cilindros-contenedor">
        {_.map(products, (product, index) =>(
          <a className="cilindro transition" key={product.id} onClick={this.addToCart.bind(this, product)}>
            <img src={product.attachment.url}/>
            <p>Gas Licuado</p>
            <hr/>
            <h4>{product.product_format}</h4>
          </a>
        ))}
      </div>
    )
  }
}

import React, { Component } from 'react';
import { Button, Form, Container, Row, Col, FormGroup, Input } from 'reactstrap';
import _ from 'lodash';
import NumberFormat from 'react-number-format';

export default class ShoppingCart extends Component {
  constructor(props){
    super(props);
    this.state = {
      ...props,
      cart: this.props.cartItems
    };
    this.productOrder = this.productOrder.bind(this);
  }

  componentDidMount(){
  }

	feed(productID){
    let value = this.refs['feedQty' + productID].value
    if (value <= 1) {
      value = 1;
    }
    this.props.updateQuantity(productID, value);
	};

  productOrder(){
    let cartItems = this.state.cart;
    return(
      _.map(cartItems, (product, index) =>(
        <div className="active-product-list" key={product.id}>
          <p>Gas Licuado [{product.product_format}]</p>
          <p>Cantidad</p>
          <div className="input-group number-spinner">
            <div className="input-group number-spinner">
              <input ref={"feedQty" + product.id} type="number" className="form-control-custom text-center" min="1" value={product.quantity} onChange={this.feed.bind(this, product.id)} />
      			</div>
          </div>
          <NumberFormat
            className="price"
            value={(product.price * product.quantity)}
            displayType={'text'}
            thousandSeparator={'.'}
            prefix={'$'}
            decimalSeparator={','}
          />
          <button type="button" className="close transition" aria-label="Close" onClick={this.props.removeProduct.bind(this, product.id)}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      ))
    )
  }

  render(){
    const { cartItems } = this.props;
    if (cartItems === undefined || cartItems === null || _.isEmpty(cartItems)) {
      return null;
    }
    return(
      <div>
        <div className="row">
          <div className="col-md-12">
            <h4>Resumen de tu Orden</h4>
          </div>
          <div className="col-md-12 responsive-product-list">
            {this.productOrder()}
          </div>
        </div>
        <div className="total-container col-md-12">
          <p>Compra total</p>
          <h4>
            <NumberFormat
              value={this.props.total}
              displayType={'text'}
              thousandSeparator={'.'}
              prefix={'$'}
              decimalSeparator={','}
            />
          </h4>
          <hr className="hidden-xs" />
        </div>
        <div className="spacer"></div>
      </div>
    )
  }
}

import React, { Component } from 'react';
import { Button, Form, Container, Row, Col, FormGroup, Input } from 'reactstrap';
import FieldWrapper from '../../../common/components/field_wrapper';
import * as moment from 'moment';
import webpay_logo from 'images/webpay.jpg';
import _ from 'lodash';
import Products from './Products';
import ShoppingCart from './ShoppingCart';
import Addresses from './Addresses';
import CheckOut from './CheckOut';
import spinner from 'images/spinner.gif';
import FadeIn from 'react-fade-in';

export default class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      cart: [],
      totalItems: 0,
      totalAmount: 0,
      quantity: 1,
      edit: true,
      communes_html: '',
      transient_address: {
        street_name: '',
        street_number: '',
        city: '',
        commune: '',
        block: '',
        observations: '',
        main_address: false,
        selected: false
      },
      errors: {
        street_name: [],
        street_number: [],
        city: [],
        commune: []
      }
    };
    this.handleAddToCart = this.handleAddToCart.bind(this);
    this.checkProduct = this.checkProduct.bind(this);
    this.sumTotalItems = this.sumTotalItems.bind(this);
		this.sumTotalAmount = this.sumTotalAmount.bind(this);
    this.handleRemoveProduct = this.handleRemoveProduct.bind(this);
    this.updateQuantity = this.updateQuantity.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleChangeCity = this.handleChangeCity.bind(this);
    this.handleChangeCommune = this.handleChangeCommune.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.checkAddress = this.checkAddress.bind(this);
    this.validates = this.validates.bind(this);
    this.toTitleCase = this.toTitleCase.bind(this);
  }

  componentDidMount(){
    let { communes_html } = this.state;
    communes_html = $('.chosen.commune').html();
    $('.chosen#commune').empty();
    $('.chosen#commune').trigger('chosen:updated');
    $('.chosen#city').change(this.handleChangeCity);
    $('.chosen#commune').change(this.handleChangeCommune);
    this.setState({ communes_html });
  }

  componentDidUpdate(){
    $('.chosen#city').trigger("chosen:updated");
    $('.chosen#commune').trigger("chosen:updated");
  }

  handleSubmit(event){
    event.preventDefault();
    let { transient_address, addresses, edit } = this.state;
    if (this.validates()) {
      edit = false;
      transient_address.selected = true;
      this.checkAddress(addresses);
      addresses.push(transient_address);
      this.setState({ transient_address, addresses, edit });
    }
  }

  handleSelect(a){
    let { addresses } = this.state;
    let index = addresses.findIndex((x => x.street_number == a.street_number))
    _.map(addresses, (address, i) => {
      if (i === index) {
        address.selected = true;
      }else {
        address.selected = false;
      }
    })
    this.setState({ addresses });
  }

  handleEdit(a){
    let { addresses, transient_address, edit } = this.state;
    let index = addresses.findIndex((x => x.street_number == a.street_number))
    transient_address.selected = false;
    addresses.splice(index, 1);
    edit = true;
    this.setState({ addresses, transient_address, edit });
  }

  handleChangeInput(event){
    let { transient_address, errors } = this.state;
    let value = event.target.value;
    if (event.target.name == "street_number" || event.target.name == "block") {
      transient_address[event.target.name] = value;
    }else {
      transient_address[event.target.name] = this.toTitleCase(value);
    }
    errors[event.target.name] = [];
    this.setState({ transient_address, errors });
  }

  handleChangeCity(event){
    let { transient_address, errors, communes_html } = this.state;
    let value = event.target.value;
    let options = $(communes_html).filter("optgroup[label='" + value + "']").html();
    if (options) {
      $('.chosen#commune').html(options);
      $('.chosen#commune').prepend($("<option/>", {
        value: "",
        text: ""
      }));
      transient_address['commune'] = '';
    }else {
      $('.chosen#commune').empty();
    }
    transient_address[event.target.name] = value;
    errors[event.target.name] = [];
    this.setState({ transient_address, errors });
  }

  handleChangeCommune(event){
    let { transient_address, errors } = this.state;
    let value = event.target.value;
    transient_address[event.target.name] = value;
    errors[event.target.name] = [];
    this.setState({ transient_address, errors });
  }

  checkAddress(addresses){
    _.map(addresses, (address, index) =>{
      if (address.selected) {
        address.selected = false;
      }
    });
    this.setState({ addresses })
  }

  validates(){
    let { transient_address, errors } = this.state;
    var is_valid = true;
    if (transient_address.street_name == '') {
      errors["street_name"] = ["Este campo es obligatorio"];
      is_valid = false;
    }
    if (transient_address.street_number == '') {
      errors["street_number"] = ["Este campo es obligatorio"];
      is_valid = false;
    }
    if (transient_address.city == '') {
      errors["city"] = ["Este campo es obligatorio"];
      is_valid = false;
    }
    if (transient_address.commune == '') {
      errors["commune"] = ["Este campo es obligatorio"];
      is_valid = false;
    }
    this.setState({ errors });
    return is_valid;
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  renderForm(){
    let { transient_address, edit, errors, cities, communes } = this.state;
    return(
      <div style={{display: edit ? "" : "none"}}>
        <div className="col-md-12">
          <h4>Dirección de Entrega</h4>
          <p>Ingresa aquí una dirección alternativa a la cual será enviada tu orden</p>
          <div className="spacer"></div>
        </div>

        <Form onSubmit={this.handleSubmit}>
          <div className="col-md-7 nopadding">
            <div className="form-group col-xs-12 col-sm-8">
              <FieldWrapper errors={errors["street_name"]}>
                <Input
                  type="text"
                  placeholder="Calle"
                  className="form-control-custom"
                  name="street_name"
                  id="street_name"
                  value={transient_address.street_name}
                  onChange={this.handleChangeInput}
                />
              </FieldWrapper>
            </div>
            <div className="form-group col-xs-12 col-sm-4">
              <FieldWrapper errors={errors["street_number"]}>
                <Input
                  type="text"
                  placeholder="Nº"
                  className="form-control-custom"
                  name="street_number"
                  id="street_number"
                  value={transient_address.street_number}
                  onChange={this.handleChangeInput}
                />
              </FieldWrapper>
            </div>
            <div className="form-group col-xs-12 col-sm-6">
              <FieldWrapper errors={errors["city"]}>
                <Input
                  type="select"
                  data-placeholder='Seleccione una Ciudad'
                  className="form-control-custom chosen city"
                  name="city"
                  id="city"
                  value={transient_address.city}
                  onChange={this.handleChangeCity}
                  ref="city"
                >
                  <option value=''></option>
                  {_.map(cities, (city, index) => (
                    <option key={'city-selected' + index} value={city.name}>{city.name}</option>
                  ))}
                </Input>
              </FieldWrapper>
            </div>
            <div className="form-group col-xs-12 col-sm-6">
              <FieldWrapper errors={errors["commune"]}>
                <Input
                  type="select"
                  data-placeholder='Seleccione una Comuna'
                  className="form-control-custom chosen commune"
                  name="commune"
                  id="commune"
                  value={transient_address.commune}
                  onChange={this.handleChangeCommune}
                >
                  <option value=''></option>
                  {_.map(cities, (city, index) => (
                    <optgroup key={city.id} label={city.name}>
                      {_.map(communes, (commune, index) => {
                        if (city.id === commune.city_id) {
                          return (<option key={'commune-selected' + index} value={commune.name}>{commune.name}</option>)
                        }
                      })}
                    </optgroup>
                  ))}
                </Input>
              </FieldWrapper>
            </div>
            <div className="form-group col-xs-12">
              <FieldWrapper errors={errors["block"]}>
                <Input
                  type="text"
                  placeholder="Block/Depto"
                  className="form-control-custom"
                  name="block"
                  id="block"
                  value={transient_address.block}
                  onChange={this.handleChangeInput}
                />
              </FieldWrapper>
            </div>
          </div>
          <div className="col-md-5 col-xs-12 nopadding">
            <div className="form-group col-xs-12">
              <Input
                type="textarea"
                placeholder="Observaciones"
                className="form-control-custom"
                name="observations"
                id="observations"
                value={transient_address.observations}
                onChange={this.handleChangeInput}
              />
            </div>
          </div>

          <div className="col-md-12 text-right">
            <button type="submit" className="btn btn-form">Seleccionar</button>
          </div>
        </Form>
      </div>
    )
  }

  handleAddToCart(selectedProduct){
    let cartItem = this.state.cart;
		let productID = selectedProduct.id;
		let productQty = selectedProduct.quantity;
    if(this.checkProduct(productID)){
			let index = cartItem.findIndex((x => x.id == productID));
			cartItem[index].quantity = Number(cartItem[index].quantity) + Number(productQty);
			this.setState({
				cart: cartItem
			})
		} else {
			cartItem.push(selectedProduct);
		}
		this.setState({
			cart : cartItem
		});
    setTimeout(function(){
			this.setState({
				quantity: 1
			});
    }.bind(this),1000);
		this.sumTotalItems(this.state.cart);
		this.sumTotalAmount(this.state.cart);
  }

  handleRemoveProduct(id, e){
		let cart = this.state.cart;
		let index = cart.findIndex((x => x.id == id));
		cart.splice(index, 1);
		this.setState({
			cart: cart
		})
		this.sumTotalItems(this.state.cart);
		this.sumTotalAmount(this.state.cart);
		e.preventDefault();
	}

  checkProduct(productID){
		let { cart }  = this.state;
		return cart.some(function(item) {
			return item.id === productID;
		});
  }

  sumTotalItems(){
    let total = 0;
    let { cart } = this.state;
		total = cart.length;
		this.setState({
			totalItems: total
		})
  }

  sumTotalAmount(){
    let total = 0;
    let { cart } = this.state;
    for (var i = 0; i < cart.length; i++) {
      total += cart[i].price * parseInt(cart[i].quantity);
    }
    this.setState({
      totalAmount: total
    })
  }

  updateQuantity(id, qty){
    let cart = this.state.cart;
		let index = cart.findIndex((x => x.id == id));
    cart[index].quantity = qty;
    this.setState({
      cart: cart
    })
    this.sumTotalItems(this.state.cart);
		this.sumTotalAmount(this.state.cart);
	}

  render() {
    const {
      products, addresses, cart, totalItems, totalAmount, current_user,
      web_pay_success_order_url, web_pay_fail_url, web_pay_error_url
    } = this.state;
    const {
      handleAddToCart, handleRemoveProduct, renderForm,
      updateQuantity, handleSelect, handleEdit
    } = this;
    const current_day = moment().format('dddd DD');
    const current_month = moment().format('MMMM / YYYY');
    return (
      <div className="col-md-9">
        <div className="container-white-box">
          <FadeIn>
            <div className="row">
              <div className="col-md-12">
                <FadeIn>
                  <div className="title-btn-flex">
                    <h1>Haz aquí tu pedido de cilindros</h1>
                    <div className="fecha-actual">
                      <h4>{current_day}</h4>
                      <p>{current_month}</p>
                    </div>
                  </div>

                  <Products
                    products={products}
                    addToCart={handleAddToCart}
                  />

                  <ShoppingCart
                    total={totalAmount}
                    totalItems={totalItems}
                    cartItems={cart}
                    removeProduct={handleRemoveProduct}
                    updateQuantity={updateQuantity}
                  />

                  <div className="spacer"></div>

                  {renderForm()}
                </FadeIn>
              </div>
            </div>

            <div className="spacer"></div>

            <Addresses
              addresses={addresses}
              handleSelect={handleSelect}
              handleEdit={handleEdit}
              cartItems={cart}
            />

            <CheckOut
              cart={cart}
              addresses={addresses}
              totalItems={totalItems}
              totalAmount={totalAmount}
              currentUser={current_user}
              web_pay_success_order_url={web_pay_success_order_url}
              web_pay_fail_url={web_pay_fail_url}
              web_pay_error_url={web_pay_error_url}
            />
          </FadeIn>
        </div>
      </div>
    )
  }
}

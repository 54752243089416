import React, { Component } from 'react'
import webpay_logo from 'images/webpay.jpg';
import swal from 'sweetalert2';
import { Button, Form, Container, Row, Col, FormGroup, Input } from 'reactstrap';
import { saveOrder } from '../actions/index';
import _ from 'lodash';

export default class CheckOut extends Component {
  constructor(props){
    super(props)
    this.state = {
      ...props
    };
    this.handleCheckout = this.handleCheckout.bind(this);
    this.validateCheckOut = this.validateCheckOut.bind(this);
  }

  componentDidMount(){
  }

  handleCheckout(event){
    event.preventDefault();
    let {
      totalItems, totalAmount, current_user, cart, addresses, web_pay_success_document_url,
      web_pay_fail_url, web_pay_error_url
    } = this.props;
    let validateCheckOut = this.validateCheckOut();
    if (validateCheckOut.is_valid) { // Redirect to WebPay and create Order
      saveOrder(this.serializeOrder(), (response) =>{
        if (response.status == 200 || response.status == 201) {
          // debugger
        }else {
          $.notify("Ha ocurrido un error", "error");
        }
      });
    }else {
      swal({
        title: "Advertencia",
        text: validateCheckOut.message,
        type: "warning"
      })
    }
  }

  validateCheckOut(){
    let { cart, addresses } = this.state;
    var is_valid = true;
    var message = '';
    if (!addresses.some(address => address.selected)) {
      message = 'Debes seleccionar una dirección de despacho.'
      is_valid = false
    }
    if (cart === undefined || cart === null || _.isEmpty(cart)) {
      message = 'Debes agregar cilindros a tu pedido.'
      is_valid = false;
    }
    return {is_valid, message};
  }

  serializeOrder(){
    let { cart, addresses, currentUser } = this.state;
    let { totalAmount, totalItems  } = this.props;
    var formData = new FormData();
    formData.append('user_rut', currentUser.rut);
    formData.append('user_token', currentUser.authentication_token);
    formData.append('order[client_id]', currentUser.client.id);
    formData.append('order[total_amount]', totalAmount);
    formData.append('order[total_items]', totalItems);
    _.map(cart, (item, index) => {
      formData.append('order[order_products_attributes]['+ index +'][product_id]', item.id)
      formData.append('order[order_products_attributes]['+ index +'][quantity]', item.quantity)
    })

    return formData;
  }

  render(){
    return(
      <div className="bloque-gris webpay row">
        <div className="col-md-5 text-center">
          <img src={webpay_logo} /> <br/>
          <input type="radio" className="form-check-input" name="optionsRadios" id="optionsRadios1" value="option1" checked  readOnly/>
            Tarjeta de Crédito o Redcompra
        </div>

        <div className="col-md-2"></div>
        <div className="col-md-5">
          <div className="spacer hidden-md hidden-lg"></div>
          <Form onSubmit={this.handleCheckout}>
            <button type="submit" className="btn btn-block btn-form solicitar">Solicitar Cilindro</button>
          </Form>
          <div className="spacer"></div>
          <p>
            * Serás redirigido al portal de pago de webpay donde debes ingresar los datos solicitados por webpay plus.
          </p>
        </div>
      </div>
    )
  }
}

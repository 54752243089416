import React, { Component } from 'react';
import { Button, FormText } from 'reactstrap';

export default class FieldWrapper extends Component {
  render() {
    const { errors, children } = this.props;
    if(errors && errors.length > 0){
      return (
        <div>
          {this.props.children}
          <FormText>
            <ul className="errors_list">
              {
                errors.map(function (error, index) {
                  return <li key={index}>{error}</li>
                })
              }
            </ul>
          </FormText>
        </div>
      );
    }else{
      return (
        <div>
          {this.props.children}
        </div>
      );
    }
  }
}

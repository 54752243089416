import React, { Component } from 'react';
import {
  Button, Form, Container, Row, Col, FormGroup, Input
} from 'reactstrap';
import Modal from 'react-modal';
import _ from 'lodash';
import * as moment from 'moment';
import { ColumnChart } from 'react-chartkick'
import NumberFormat from 'react-number-format';
import {
  getData, getBillHistorical, getCurrentDebts, setAfterLogin, savePayment
} from '../actions/index';
import spinner from 'images/spinner.gif';
import FadeIn from 'react-fade-in';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

export default class GlpBillsSummary extends Component {
  constructor(props){
    super(props);
    this.state = {
      ...props,
      current_debt: {},
      data_chart: {},
      first_data: '',
      last_data: '',
      messages: [],
      max_consumption: [],
      min_consumption: [],
      average_consumption: 0,
      error: '',
      loading: true,
      data: {},
      showModal: false,
      current_debts: [],
      error_current_debts: '',
      loading_current_debts: false
    }
    this.toggleModal = this.toggleModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    setTimeout(function(){
      getData((response) => {
        this.setState({ loading: false });
        if (response.status === 200) {
          this.setState({
            current_debt: response.data.current_debt,
            data_chart: response.data.data_chart,
            first_data: response.data.comparative_data[0],
            last_data: response.data.comparative_data[1],
            messages: response.data.comparative_data[2],
            max_consumption: response.data.max_consumption,
            min_consumption: response.data.min_consumption,
            average_consumption: response.data.average_consumption
          });
        }else {
          this.setState({ error: 'Ha ocurrido un error al cargar los datos.' })
        }
      });
    }.bind(this), 1500);
    if (this.props.after_login) {
      // $("#button_covid_modal").click();
      // $("#button_maintenance_modal").click();
      setAfterLogin();
    }
  }

  async handleSubmit(event){
    event.preventDefault();
    let {
      current_client, web_pay_success_glp_url,
      web_pay_fail_url, web_pay_error_url,
      current_debts
    } = this.state;
    var total_amount = 0;
    var document_number = [];
    var document_with_quote = "";
    var check_boxes;
    event.target.method = "post";
    event.target.action = process.env.URL_PAGO_GASCO + "/Default.aspx";
    check_boxes = $(event.target.elements).filter('input[type=checkbox]');
    if (check_boxes.filter(':checked').length === 0) {
      return alert("Debe seleccionar al menos una deuda.");
    }
    _.map(current_debts, (current_debt, index) => {
      _.map(check_boxes, (input, idx) => {
        if ($(input).is(':checked') && ("Pago0" + (index+1) === $(input).attr('name'))) {
          total_amount = parseInt(total_amount) + parseInt(current_debt.monto)
          document_with_quote = current_debt.numerodocumento + "-" + current_debt.numerodecuota
          document_number.push(document_with_quote.replace(/^0+/, ''))
          $(input).attr({
            value: current_debt.numerodocumento + ";" +
              current_debt.numerodecuota + ";" +
              current_debt.monto + ";" +
              current_debt.bukrs + ";" +
              current_debt.belnr + ";" +
              current_debt.gjahr + ";" +
              current_debt.buzei + ";" +
              current_debt.fechadpi + ";" +
              current_debt.montodpi + ";" +
              current_debt.ufdpi + ";" +
              current_debt.interesdpi + ";" +
              current_debt.interesmldpi + ";" +
              current_debt.cobexternadpi + ";" +
              current_debt.fechavtodpi + ";" +
              current_debt.diasmoradpi + ";" +
              "#"
          })
        }
      });
    });
    document_number.sort(function(a,b) {
      if (isNaN(a) || isNaN(b)) {
        return a > b ? 1 : -1;
      }
      return a - b;
    });
    document_number = _.join(document_number, ", ")
    event.persist();
    let response = await savePayment(this.serializeTotalBill(total_amount, document_number), (response) => {
      if (response.status === 201) {
        $('<input>').attr({
          type: 'hidden',
          name: 'hfVkorg',
          value: '4000'
        }).appendTo(event.target);
        $('<input>').attr({
          type: 'hidden',
          name: 'hfTipoTRX',
          value: '1'
        }).appendTo(event.target);
        $('<input>').attr({
          type: 'hidden',
          name: 'hfKCC',
          value: process.env.KCCGLP
        }).appendTo(event.target);
        $('<input>').attr({
          type: 'hidden',
          name: 'hfNumeroCliente',
          value: current_client.client_number
        }).appendTo(event.target);
        $('<input>').attr({
          type: 'hidden',
          name: 'hfMontoTotal',
          value: total_amount
        }).appendTo(event.target);
        $('<input>').attr({
          type: 'hidden',
          name: 'hfUserName',
          value: 'ZWu'
        }).appendTo(event.target);
        $('<input>').attr({
          type: 'hidden',
          name: 'hfUrlExito',
          value: web_pay_success_glp_url
        }).appendTo(event.target);
        $('<input>').attr({
          type: 'hidden',
          name: 'hfUrlFracaso',
          value: web_pay_fail_url
        }).appendTo(event.target);
        $('<input>').attr({
          type: 'hidden',
          name: 'hfUrlError',
          value: web_pay_error_url
        }).appendTo(event.target);
        $('<input>').attr({
          type: 'hidden',
          name: 'hfretry',
          value: '0'
        }).appendTo(event.target);
        event.target.submit();
      } else {
        alert("Error al procesar la información")
        console.log(response);
      }
    })
  }

  serializeTotalBill(total_amount, document_number){
    let { current_user, current_client } = this.state;
    var formData = new FormData();
    formData.append('user_rut', current_user.rut);
    formData.append('user_token', current_user.authentication_token);
    formData.append('payment[client_number]', current_client.client_number);
    formData.append('payment[number]', document_number);
    formData.append('payment[tbk_amount]', total_amount);
    formData.append('payment[total_bill]', true);
    formData.append('payment[send_email_notification]', true);
    formData.append('payment[email_notification]', current_user.email);

    return formData;
  }

  toggleModal(){
    let { showModal } = this.state;
    showModal = !showModal;
    this.setState({ showModal, loading_current_debts: true });
    getCurrentDebts((response) => {
      this.setState({ loading_current_debts: false})
      if (response.status === 200) {
        if (response.data.current_debts) {
          this.setState({ current_debts: response.data.current_debts })
        } else {
          this.setState({
            error_current_debts: response.data.error
          })
        }
      }else {
        this.setState({ error_current_debts: 'Ha ocurrido un error al cargar los datos.' })
      }
    })
  }

  renderModal(){
    let { current_debts, error_current_debts, loading_current_debts } = this.state;
    return(
      <Modal isOpen={this.state.showModal} toggle={this.toggleModal} style={customStyles}>
        <div className={'modal-header'}>
          <h3>Pagar deudas</h3>
        </div>
        <div className={'modal-body'}>
          {loading_current_debts ? (
            <FadeIn>
              <div style={{paddingBottom: '80px'}}>
                <img src={spinner} className="img-center"/>
              </div>
            </FadeIn>
          ) : (
            <Form onSubmit={this.handleSubmit} className="form_pago" id="glp-form">
              <ul>
                {_.map(current_debts, (current_debt, index) => (
                  <li key={index}>
                    <Input
                      type="checkbox"
                      name={"Pago0" + (index+1)}
                      id={"Pago0" + (index+1)}
                    />{' '}
                    {"Documento "}{current_debt.pdf_url ? (<a href={current_debt.pdf_url} target="blank">{current_debt.numerodocumento}</a>) : current_debt.numerodocumento}{"; Cuota " + current_debt.numerodecuota + "; Monto"}{
                      <NumberFormat
                        value={current_debt.monto ? current_debt.monto : 0}
                        displayType={'text'}
                        thousandSeparator={'.'}
                        prefix={'$'}
                        decimalSeparator={','}
                      />
                    }
                  </li>
                ))}
              </ul>
            </Form>
          )}
        </div>
        <div className={'modal-footer'}>
          <Button color="danger" onClick={this.toggleModal}>Cancelar</Button>
          <Button color="success" form="glp-form" key="submit" type="submit">
            Pagar
          </Button>
        </div>
      </Modal>
    )
  }

  renderComparativeData(){
    let { messages, first_data, last_data } = this.state;
    if (messages === null || _.isEmpty(messages)) {
      return null;
    }else if (messages[1] === 0) {
      return(
        <li>El consumo de {last_data} es {messages[0]} comparado con el consumo de {first_data}.</li>
      )
    } else {
      return(
        <li>El consumo de {last_data} es {messages[1]}% {messages[0]} comparado con el consumo de {first_data}.</li>
      )
    }
  }

  renderData(){
    let { average_consumption, max_consumption, min_consumption, data_chart, current_debt, reading_date } = this.state;
    if (max_consumption === null || _.isEmpty(max_consumption) || min_consumption === null || _.isEmpty(min_consumption)) {
      return null;
    }else {
      return(
        <div>
          <div className="col-md-7 col-sm-12">
            <ul className="detalle">
              <li>El consumo del último mes es de {current_debt.consumption_m3} {current_debt.measurement}.</li>
              {this.renderComparativeData()}
              <li>Su consumo promedio mensual es {average_consumption} {current_debt.measurement}.</li>
              <li>El consumo máximo de {max_consumption[0]} {current_debt.measurement} se presenta en el {max_consumption[1]}.</li>
              <li>El consumo mínimo de {min_consumption[0]} {current_debt.measurement} se presenta en el {min_consumption[1]}.</li>
            </ul>
          </div>
          <div className="col-md-5 col-sm-12">
            <h4>Consumo por meses</h4>
            <ColumnChart data={data_chart} label={current_debt.measurement} colors={["#9a0031"]} library={{hAxis: {textPosition: 'none' }}} />
            <br/>
            <br/>
            <a className="transition" href="/glps/bills/historical">Ver historial de consumo</a>
          </div>
        </div>
      )
    }
  }

  renderBlock(){
    let { current_debt, total_debt } = this.state;
    let total_paid = current_debt.total_paid < 0 ? 0 : current_debt.total_paid;
    let tax = parseInt(current_debt.tax) < 0 ? 0 : parseInt(current_debt.tax);
    return(
      <div className="bloque-gris">
        <div className="title-btn-flex">
          <h4>Deuda Total</h4>
          <Button type="button" className="btn pagar-boleta transition" onClick={this.toggleModal}>
            Pagar Deudas
          </Button>
          {this.renderModal()}
        </div>
        <hr/>
        <div className="row">
          <div className="col-md-4 col-sm-12 container-boleta-mes">
            <p><b>Consumo Total</b></p>
            <p className="price-cancelada price">
              <NumberFormat
                value={total_debt ? total_debt : 0}
                displayType={'text'}
                thousandSeparator={'.'}
                prefix={'$'}
                decimalSeparator={','}
              />
            </p>
            {/*<a href={current_debt.pdf} target="_blank" className="btn ver-boleta transition">Ver Documento</a>*/}
          </div>
          <div className="col-md-8 col-sm-12">
            <table className="tabla-consumo">
            <tbody>
            <tr>
            <td>Fecha de vencimiento:</td>
            <td className="table-bold">{current_debt.due_date}</td>
            </tr>
            </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }

  render() {
    let { error, loading } = this.state;
    const current_day = moment().format('dddd DD');
    const current_month_year = moment().locale('es').format('MMMM / YYYY');
    if (loading) {
      return(
        <div className="col-md-9">
          <div className="container-white-box">
            <FadeIn>
              <div style={{paddingBottom: '80px'}}>
                <img src={spinner} className="img-center"/>
              </div>
            </FadeIn>
          </div>
        </div>
      )
    }else {
      if (error.trim() !== '') {
        return(
            <div className="col-md-9">
              <div className="container-white-box">
                <FadeIn>
                  <p>{error}</p>
                </FadeIn>
              </div>
            </div>
        )
      }
      return(
        <div className="col-md-9">
          <div className="container-white-box transition">
            <div className="row">
              <div className="col-md-12">
                <FadeIn>
                  <div className="title-btn-flex">
                    <h1>Resumen de consumos</h1>
                    <div className="fecha-actual">
                      <h4>{current_day}</h4>
                      <p>{current_month_year}</p>
                    </div>
                  </div>

                  {this.renderBlock()}

                  <div className="row">
                    <div className="col-md-12">
                      <div className="bloque-gris">
                        <div className="row">
                          {this.renderData()}
                        </div>
                      </div>
                    </div>
                  </div>
                </FadeIn>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

import ReactOnRails from 'react-on-rails';
import regeneratorRuntime from "regenerator-runtime";

import BillsSummary from '../bundles/NaturalGas/Bill/components/BillsSummary';
import BillsHistorical from '../bundles/NaturalGas/Bill/components/BillsHistorical';
import Order from '../bundles/LiquidGas/Order/components/Order';
import Products from '../bundles/LiquidGas/Order/components/Products';
import ShoppingCart from '../bundles/LiquidGas/Order/components/ShoppingCart';
import Addresses from '../bundles/LiquidGas/Order/components/Addresses';
import CheckOut from '../bundles/LiquidGas/Order/components/CheckOut';
import PayAccount from '../bundles/WebPay/components/PayAccount';
import PayGlpAccount from '../bundles/WebPay/components/PayGlpAccount';
import GlpBillsSummary from '../bundles/Glp/Bill/components/GlpBillsSummary';
import GlpBillsHistorical from '../bundles/Glp/Bill/components/GlpBillsHistorical';

import * as moment from 'moment';

moment.updateLocale('es', {
  months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
  monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dic.'.split('_'),
  weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
  weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
  weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
});

ReactOnRails.register({
  BillsSummary,
  BillsHistorical,
  Order,
  Products,
  ShoppingCart,
  Addresses,
  CheckOut,
  PayAccount,
  PayGlpAccount,
  GlpBillsSummary,
  GlpBillsHistorical
});

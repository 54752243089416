import axios from 'axios';

export function getBillHistorical(callback){
  const promise = axios.get(`/api/v1/documents/glps`)
  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      if (error.response) {
        callback(error.response);
      }
    })
}

export function getData(callback){
  const promise = axios.get(`/api/v1/documents/glps/data`)
  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      if (error.response) {
        callback(error.response);
      }
    })
}

export function getCurrentDebts(callback){
  const promise = axios.get(`/api/v1/documents/glps/get_current_debts`)
  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      if (error.response){
        callback(error.response);
      }
    })
}

export function getPdf(values, callback){
  const promise = axios.post(`/api/v1/documents/glps/pdf`, values)
  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      if (error.response) {
        callback(error.response);
      }
    })
}

export function setAfterLogin(callback){
  const promise = axios.get(`/api/v1/documents/set_after_login`)
  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      if (error.response) {
        callback(error.response);
      }
    })
}

export function savePayment(values, callback){
  const promise = axios.post(`/api/v1/payments`, values)
  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      if (error.response) {
        callback(error.response);
      }
    })
}
